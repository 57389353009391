<template>
    <base-page>
        <titlu-pagina Titlu="Setari pontaj" />
    
        <el-row >
            <el-col :span="8">
                <el-card style='margin:5px 0px 5px 0px'>
                    <div slot="header">
                        <span>Ore angajati</span>
                        <el-button @click="save" style="float: right; padding: 6px 12px; " type="success" plain round size="large"> Salveaza </el-button>
                    </div>
                    <el-form label-position="top" :model="SetariNumarOre" :inline="false" :rules="rules" label-width="100%"  ref='my-form' @submit.prevent="save"  >
                        <el-row>
                            <el-form-item label='Numar maxim de ore pe zi pentru angajat propriu' prop="NumarMaximOreZiAngajatPropriu">
                                <el-input-number class='full-width' v-model='SetariNumarOre.NumarMaximOreZiAngajatPropriu' :max="24" :min="0"/>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label='Numar maxim de ore pe zi pentru angajat cooptat' prop="NumarMaximOreZiAngajatCooptat">
                                <el-input-number class='full-width' v-model='SetariNumarOre.NumarMaximOreZiAngajatCooptat' :max="24" :min="0"/>
                            </el-form-item> 
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "Setari",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            SetariNumarOre: {NumarMaximOreZiAngajatPropriu:'', NumarMaximOreZiAngajatCooptat:''},
            Results: [],
            base_url: '',
            rules: {
                    NumarMaximOreZiAngajatPropriu:  [ { required: true, message: 'Campul este obligatoriu' } ],
                    NumarMaximOreZiAngajatCooptat:  [ { required: true, message: 'Campul este obligatoriu' } ],
                }
        }
    },
    methods: {

        async get_info(){
            var response            = await this.post("setari/index" );
            this.SetariNumarOre     = response.SetariNumarOre;
        },
        save: async function() {
            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {
                    var save_response = await this.post("setari/save_ore_angajati", {SetariNumarOre: this.SetariNumarOre } );

                    if(save_response.Raspuns){
                        this.$notify({
                            title: 'Succes',
                            message: 'Modificarile au fost salvate!',
                            type: 'success',
                        });
                    } else {
                        this.$notify({
                            title: 'Eroare',
                            message: 'A aparut o problema - modificarile nu au fost salvate!',
                            type: 'error',
                        });
                    }
                }
                });
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>



</style>
